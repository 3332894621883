import {
  IViewConfigsProvider,
  VIEW_CONFIGS_PROVIDER_TOKEN,
} from '@integration-frontends/core/ui/view-configs-provider';
import { SECTION_UPLOAD_PAGE_TOKEN } from '@integration-frontends/integration/ui/attachment-selector/components/section-upload-page';
import {
  ATTACHMENT_ACTION_PROVIDER_TOKEN,
  ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  IAttachmentActionProvider,
  IAttachmentSelectorFeaturesService,
  IntegrationUiModule,
} from '@integration-frontends/integration/ui';
import {
  AuthenticationServiceTokenStrategy,
  IdentityStoreLocalStorageStrategy,
} from '@integration-frontends/common/auth/core/infrastructure';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import {
  BRANDFOLDER_API_TOKEN,
  BrandfolderApi,
} from '@integration-frontends/common/brandfolder-api';
import {
  COMPONENT_RESOLVER_TOKEN,
  ComponentResolver,
  DI_CONTAINER,
  ILogger,
  initI18n,
  LOGGER_TOKEN,
  IObservabilityService,
  ObservabilityServiceDatadogStrategy,
  OBSERVABILITY_SERVICE_TOKEN,
  IAnalyticsService,
  ANALYTICS_SERVICE_TOKEN,
  AnalyticsServiceStrategy,
} from '@integration-frontends/core';
import {
  ISelectAttachmentOptions,
  IUploadAssetsOptions,
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  SelectActionType,
  SelectionType,
  UPLOAD_ASSETS_OPTIONS_TOKEN,
} from '@integration-frontends/integration/core/application';
import {
  ALL_MEDIA_TYPES,
  ASSET_REPO_TOKEN,
  ATTACHMENT_REPO_TOKEN,
  ATTACHMENT_TRANSFORM_SERVICE_TOKEN,
  BRANDFOLDER_REPO_TOKEN,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  CDN_SERVICE_TOKEN,
  COLLECTION_REPO_TOKEN,
  CONTAINER_REPO_TOKEN,
  DOWNLOAD_SERVICE_TOKEN,
  IAssetRepo,
  IAttachmentRepo,
  IAttachmentTransformService,
  IBrandfolderRepo,
  IBrandfolderWebsiteService,
  ICdnService,
  ICollectionRepo,
  IContainerRepo,
  IDownloadService,
  ILabelRepo,
  IMediaTypeSupportService,
  IOrganizationRepo,
  ISearchFilterRepo,
  ISectionRepo,
  ISharingService,
  IUserSettingsRepo,
  LABEL_REPO_TOKEN,
  MEDIA_TYPE_SUPPORT_SERVICE_TOKEN,
  ORGANIZATION_REPO_TOKEN,
  SEARCH_FILTER_REPO_TOKEN,
  SECTION_REPO_TOKEN,
  SHARING_SERVICE_TOKEN,
  USER_SETTINGS_REPO_TOKEN,
} from '@integration-frontends/integration/core/model';
import {
  AssetRepo,
  AttachmentTransformService,
  BrandfolderRepo,
  BrandfolderWebsiteService,
  CdnService,
  CollectionRepo,
  ContainerRepo,
  DownloadService,
  GET_API_KEY_TOKEN,
  IGetAuthToken,
  LabelRepo,
  OrganizationRepo,
  SearchFilterRepo,
  SectionRepo,
  SharingService,
  UserSettingsRepo,
} from '@integration-frontends/integration/infrastructure/isomorphic';
import { Container } from 'inversify';
import { getApiKeyCustomStrategy } from './app/get-api-key';
import { AttachmentRepoPanelUiStrategy } from './app/infrastructure/attachment-repo-panel-ui-strategy';
import { MediaTypeSupportServicePanelUiStrategy } from './app/infrastructure/media-type-support-service-panel-ui-strategy';
import { BF_BASE_URL, BF_STATIC_ASSETS_BASE_URL, BF_WEBSITE_BASE_URL } from './config';
import { AttachmentActionsProviderPanelUIStrategy } from './app/infrastructure/attachment-actions-provider-panel-ui-strategy';
import { AttachmentSelectorFeatureServicePanelUiStrategy } from './app/infrastructure/attachment-selector-feature-service-panel-ui-strategy';
import { FigmaAssetsListHeader } from './app/ui/select-attachment/figma-assets-list-header/figma-assets-list-header';
import { IS_FIGMA } from './app/common/utilities';
import { ASSETS_LIST_HEADER_COMPONENT_TOKEN } from '@integration-frontends/integration/ui/attachment-selector/common/assets-list-header';
import FigmaUpload from './app/ui/figma/figma-upload/figma-upload';
import { ObservabilityUiModule } from '@integration-frontends/core/ui';
import { ViewConfigsProviderPanelUiStrategy } from './app/infrastructure/view-configs-provider-panel-ui-strategy';

// TODO: use BFModule. ~PP

/**
 * App
 */
const appContainer = new Container();
appContainer.bind<ILogger>(LOGGER_TOKEN).toConstantValue(console);
appContainer.bind<IIdentityStore>(IDENTITY_STORE_TOKEN).to(IdentityStoreLocalStorageStrategy);
appContainer.bind<IViewConfigsProvider>(VIEW_CONFIGS_PROVIDER_TOKEN).to(ViewConfigsProviderPanelUiStrategy);
DI_CONTAINER.merge(appContainer);

/**
 * Auth
 */
const authContainer = new Container();
authContainer
  .bind<IAuthenticateService>(AUTHENTICATE_SERVICE_TOKEN)
  .to(AuthenticationServiceTokenStrategy);

AuthUiModule.initialize(authContainer);

/**
 * Integration
 */
const integrationContainer = new Container();

integrationContainer
  .bind<BrandfolderApi>(BRANDFOLDER_API_TOKEN)
  .toConstantValue(new BrandfolderApi(BF_BASE_URL, DI_CONTAINER.get(LOGGER_TOKEN)));

integrationContainer
  .bind<IGetAuthToken>(GET_API_KEY_TOKEN)
  .toConstantValue(getApiKeyCustomStrategy);
integrationContainer.bind<IAttachmentRepo>(ATTACHMENT_REPO_TOKEN).to(AttachmentRepoPanelUiStrategy);
integrationContainer
  .bind<IAttachmentTransformService>(ATTACHMENT_TRANSFORM_SERVICE_TOKEN)
  .to(AttachmentTransformService);
integrationContainer.bind<IAssetRepo>(ASSET_REPO_TOKEN).to(AssetRepo);
integrationContainer.bind<IBrandfolderRepo>(BRANDFOLDER_REPO_TOKEN).to(BrandfolderRepo);
integrationContainer.bind<ICollectionRepo>(COLLECTION_REPO_TOKEN).to(CollectionRepo);
integrationContainer.bind<ISearchFilterRepo>(SEARCH_FILTER_REPO_TOKEN).to(SearchFilterRepo);
integrationContainer.bind<ISectionRepo>(SECTION_REPO_TOKEN).to(SectionRepo);
integrationContainer.bind<IOrganizationRepo>(ORGANIZATION_REPO_TOKEN).to(OrganizationRepo);
integrationContainer.bind<ILabelRepo>(LABEL_REPO_TOKEN).to(LabelRepo);
integrationContainer.bind<IContainerRepo>(CONTAINER_REPO_TOKEN).to(ContainerRepo);
integrationContainer.bind<IUserSettingsRepo>(USER_SETTINGS_REPO_TOKEN).to(UserSettingsRepo);
integrationContainer
  .bind<IMediaTypeSupportService>(MEDIA_TYPE_SUPPORT_SERVICE_TOKEN)
  .toConstantValue(new MediaTypeSupportServicePanelUiStrategy(ALL_MEDIA_TYPES));
integrationContainer.bind<IDownloadService>(DOWNLOAD_SERVICE_TOKEN).to(DownloadService);
integrationContainer.bind<ISharingService>(SHARING_SERVICE_TOKEN).to(SharingService);
integrationContainer
  .bind<IBrandfolderWebsiteService>(BRANDFOLDER_WEBSITE_SERVICE_TOKEN)
  .toConstantValue(new BrandfolderWebsiteService(BF_WEBSITE_BASE_URL, BF_STATIC_ASSETS_BASE_URL));
integrationContainer
  .bind<IAttachmentSelectorFeaturesService>(ATTACHMENT_SELECTOR_FEATURES_TOKEN)
  .toConstantValue(new AttachmentSelectorFeatureServicePanelUiStrategy());
integrationContainer
  .bind<ISelectAttachmentOptions>(SELECT_ATTACHMENT_OPTIONS_TOKEN)
  .toConstantValue({ actionType: SelectActionType.Place, selectionType: SelectionType.Single });
integrationContainer.bind<IUploadAssetsOptions>(UPLOAD_ASSETS_OPTIONS_TOKEN).toConstantValue({
  showAddMore: true,
  showTags: false,
  showCustomFields: false,
  showUploadAssetsButton: true,
  multipleAssetUpload: false,
});
integrationContainer
  .bind<IAttachmentActionProvider>(ATTACHMENT_ACTION_PROVIDER_TOKEN)
  .toConstantValue(new AttachmentActionsProviderPanelUIStrategy());
integrationContainer.bind<ComponentResolver>(COMPONENT_RESOLVER_TOKEN).toConstantValue(
  new ComponentResolver({
    [ASSETS_LIST_HEADER_COMPONENT_TOKEN]: IS_FIGMA ? FigmaAssetsListHeader : null,
    [SECTION_UPLOAD_PAGE_TOKEN]: IS_FIGMA ? FigmaUpload : null,
  }),
);
integrationContainer.bind<ICdnService>(CDN_SERVICE_TOKEN).to(CdnService);

integrationContainer
  .bind<IAnalyticsService>(ANALYTICS_SERVICE_TOKEN)
  .to(AnalyticsServiceStrategy);

initI18n();
IntegrationUiModule.initialize(integrationContainer);

/** Observability
 */
const observabilityContainer = new Container();
observabilityContainer
  .bind<IObservabilityService>(OBSERVABILITY_SERVICE_TOKEN)
  .to(ObservabilityServiceDatadogStrategy);

ObservabilityUiModule.initialize(observabilityContainer);
