import { StorageMethods, useStorage } from '@apps-common/utils';
import { sendMessage } from '@integration-frontends/apps/common/utils';
import { sendSegmentIdentifyAction } from '@integration-frontends/common/analytics';
import { init, initSuccess } from '@integration-frontends/common/app';
import {
  AUTHENTICATE_SERVICE_TOKEN,
  IAuthenticateService,
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';
import {
  datadogPanelAppNames,
  DI_CONTAINER,
  getObservabilityService,
  integrationFrontendsDD,
} from '@integration-frontends/core';
import { push } from 'redux-first-history';
import { call, put } from 'redux-saga/effects';
import { take } from 'typed-redux-saga';
import { environment } from '../../environments/environment';
import { getParam, PERSIST_ROUTE } from '../common/utilities';
import { SAVED_ROUTE_KEY } from './common';
import { History } from 'history';
import { Persistor } from 'redux-persist';

function* handleInit(action: ReturnType<typeof init>) {
  handleInitMessage();
  yield put(sendSegmentIdentifyAction({}));
  yield resolveStartRoute(action.payload.history, action.payload.persistor);
  yield put(initSuccess());
}

function handleInitMessage() {
  const initMsg = getParam('initMsg');
  initMsg && sendMessage({ event: 'initMsg', payload: initMsg });
}

function* resolveStartRoute(history: History, persistor: Persistor) {
  const apiKeyFromUrlParams = getParam('apiKey');
  const token: string = apiKeyFromUrlParams ? apiKeyFromUrlParams : null;
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  const authService: IAuthenticateService = DI_CONTAINER.get(AUTHENTICATE_SERVICE_TOKEN);

  const appName = (
    new URLSearchParams(window.location.search).get('appName') || 'panel-ui'
  ).toLowerCase();
  const service = datadogPanelAppNames.includes(appName)
    ? `integration-${appName}`
    : 'integration-panel-ui';

  if (token) {
    const identity = yield call(authService.authenticate, token);
    yield call(identityStore.set, identity);
  }

  const identity = yield call(identityStore.get);
  const isAuthed = yield call(authService.isAuthenticated, identity?.token);

  if (!isAuthed) {
    yield put(push('/getting-started'));
  } else {
    yield call(getObservabilityService().initObservability, {
      enableSessionRecordings: true,
      env: environment?.environment,
      datadogCredentials: integrationFrontendsDD,
      service,
    });
    yield call(getObservabilityService().sendIdentity, identity, environment?.environment);
    yield put(sendSegmentIdentifyAction({ identity }));

    if (history.location.pathname === '/') {
      if (PERSIST_ROUTE) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const savedRoute = useStorage(StorageMethods.GetItem, SAVED_ROUTE_KEY);
        console.log('Retrieved persisted route : ' + savedRoute);
        // hydrate the state with persisted data
        persistor.persist();
        savedRoute && savedRoute !== '/'
          ? yield put(push(savedRoute))
          : yield put(push('/select-attachment'));
      } else {
        yield put(push('/select-attachment'));
      }
    }
  }
}

export function* initWorkflow() {
  const initAction = yield take(init);
  yield handleInit(initAction);
}
