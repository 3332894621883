import { put, takeEvery } from 'typed-redux-saga';
import { updateUploadErrorToast } from '../../actions';
import { updateToast } from '@integration-frontends/common/notifications';

export function* updateUploadErrorToastEffect() {
  yield takeEvery(updateUploadErrorToast, handler);
}

const handler = function* (action: ReturnType<typeof updateUploadErrorToast>) {
  const { toastId, uploadErrorMessage } = action.payload;

  yield put(
    updateToast({
      toastContent: {
        contentText: uploadErrorMessage,
      },
      id: toastId,
    }),
  );
};
