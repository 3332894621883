import { DI_CONTAINER } from '@integration-frontends/core';
import { IGetAuthToken } from '@integration-frontends/integration/infrastructure/isomorphic';
import { getParam } from './common/utilities';
import {
  IDENTITY_STORE_TOKEN,
  IIdentityStore,
} from '@integration-frontends/common/auth/core/model';

const getApiKeyParamStrategy: IGetAuthToken = async () => {
  return getParam('apiKey');
};

const getApiKeyIdentityStrategy: IGetAuthToken = async () => {
  const identityStore: IIdentityStore = DI_CONTAINER.get(IDENTITY_STORE_TOKEN);
  return (await identityStore.get())?.token;
};

export const getApiKeyCustomStrategy: IGetAuthToken = async () =>
  (await getApiKeyParamStrategy()) || (await getApiKeyIdentityStrategy());
