import React from 'react';
import { CurrentPageDisplay } from '@integration-frontends/integration/ui/attachment-selector/common/current-page-display';
import './figma-assets-list-header.scss';
import { BFButton, ButtonSize } from '@integration-frontends/common/ui';
import { useDispatch, useSelector } from 'react-redux';
import { initiateFigmaUpload } from '../../../actions';
import {
  INTEGRATION_COMMON_NAMESPACE,
  SELECTIONS_KEY,
  UPLOAD_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import { useTranslation } from 'react-i18next';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import { useAttachmentSelectorNavigation } from '@integration-frontends/integration/ui/attachment-selector/navigation';
import { AssetsListPagination } from '@integration-frontends/integration/core/application/common/assets-list/assets-list-state/assets-list-pagination';
import { Section } from '@integration-frontends/integration/core/model';

interface FigmaAssetsListHeaderProps {
  title: string;
  pagination: AssetsListPagination;
  section: Section;
}

export function FigmaAssetsListHeader({ title, pagination, section }: FigmaAssetsListHeaderProps) {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const dispatch = useDispatch();
  const navigation = useAttachmentSelectorNavigation();
  const containerId = navigation.containerId;

  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );

  const initFigmaUpload = () => {
    navigation.goToSectionUploadPage(containerId);
    dispatch(initiateFigmaUpload({ parentContainer: selectedContainer, section: section }));
  };

  return (
    <span className="figma-assets-list-header-container">
      <div className="figma-section-header">
        <h3 className="text-primary">{title}</h3>
        <CurrentPageDisplay pagination={pagination} />
      </div>
      <BFButton className="figma-upload-button" data-testid="figma-upload-button" onClick={initFigmaUpload} size={ButtonSize.Small}>
        {t(UPLOAD_KEY)} {t(SELECTIONS_KEY)}
      </BFButton>
    </span>
  );
}
