import { UppyFile } from '@brandfolder/uploader';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';
import { FileUpload, UploadErrorType } from './reducer';
import { Asset } from '@integration-frontends/integration/core/model';

export const upload = createAction(
  'CL_ASSETS_UPLOAD',
  withPayloadType<{
    fileUploads: FileUpload[];
  }>(),
);
export const initialAssetUpload = createAction(
  'CL_INITIAL_UPLOAD_ASSET',
  withPayloadType<{
    assetUploadId: string;
    collectionId: string;
    sectionId: string;
    fileUpload: FileUpload;
  }>(),
);
export const uploadAssetSuccess = createAction(
  'CL_UPLOAD_ASSET_SUCCESS',
  withPayloadType<{
    assetUploadId: string;
  }>(),
);
export const uppyUploadSuccess = createAction(
  'CL_UPPY_UPLOAD_SUCCESS',
  withPayloadType<{
    file: UppyFile;
  }>(),
);
export const uppyUploadFilesAdded = createAction(
  'CL_UPPY_UPLOAD_FILES_ADDED',
  withPayloadType<{
    files: UppyFile[];
    uppy?;
  }>(),
);
export const uppyUploadFilesAddedSuccess = createAction('CL_UPPY_UPLOAD_FILES_ADDED_SUCCESS');
export const uppyUploadFilesAddedFailure = createAction('CL_UPPY_UPLOAD_FILES_ADDED_FAILURE');
export const uppyUploadError = createAction(
  'CL_UPLOAD_EXCEEDS_PLAN_LIMIT',
  withPayloadType<{ uploadErrorType: UploadErrorType }>(),
);
export const resetUppyUploadError = createAction(
  'CL_RESET_UPLOAD_EXCEEDS_PLAN_LIMIT',
  withPayloadType<{ uploadErrorType: UploadErrorType }>(),
);
export const addToPinnedUploads = createAction(
  'CL_ADD_TO_PINNED_UPLOAD',
  withPayloadType<{
    asset: Asset;
  }>(),
);
export const removeFromPinnedUploads = createAction(
  'CL_REMOVE_FROM_PINNED_UPLOAD',
  withPayloadType<{
    assetIds: string[];
  }>(),
);
export const updatePinnedUpload = createAction(
  'CL_UPDATE_PINNED_UPLOAD',
  withPayloadType<{
    assetId: string;
    name: string;
  }>(),
);
export const clearPinnedUploads = createAction('CL_CLEAR_PINNED_UPLOAD');
export const uploadAssetFailure = createAction(
  'CL_UPLOAD_ASSET_FAILURE',
  withPayloadType<{
    assetUploadId: string;
    error: string;
    valid?: boolean;
  }>(),
);
export const cancelAssetUploads = createAction(
  'CL_CANCEL_ASSET_UPLOADS',
  withPayloadType<{ assetUploadIds: string[] }>(),
);
export const retryAssetUpload = createAction(
  'CL_UPLOAD_ASSET_RETRY',
  withPayloadType<{ assetUploadId: string }>(),
);
export const removeAssetUploads = createAction(
  'CL_REMOVE_ASSET_UPLOADS',
  withPayloadType<{ assetUploadIds: string[] }>(),
);
export const uploadComplete = createAction('CL_FINISHED_UPLOADING_ASSETS');
export const addFilesProcessing = createAction(
  'CL_ADD_FILE_PROCESSING',
  withPayloadType<{ assetIds: string[] }>(),
);
export const removeFileProcessing = createAction(
  'CL_REMOVE_FILE_PROCESSING',
  withPayloadType<{ assetIds: string[] }>(),
);
export const updateAsset = createAction('CL_UPDATE_ASSET', withPayloadType<{ assetId: string }>());
export const cancelAssetUpload = createAction(
  'CL_ASSET_UPLOAD_CANCELLED',
  withPayloadType<{ assetUploadId: string }>(),
);
export const toggleUploadManager = createAction(
  'CL_TOGGLE_UPLOAD_MANAGER',
  withPayloadType<{ open: boolean }>(),
);
export const endUploadSequence = createAction('CL_END_UPLOAD_SEQUENCE');
export const updateUploadIndex = createAction(
  'UPDATE_UPLOAD_INDEX',
  withPayloadType<{ index: number }>(),
);
export const handleUploadErrorToast = createAction('CL_HANDLE_UPLOAD_ERROR_TOAST');
export const createUploadErrorToast = createAction(
  'CL_CREATE_UPLOAD_ERROR_TOAST',
  withPayloadType<{ toastId: string; uploadErrorMessage: string }>(),
);
export const updateUploadErrorToast = createAction(
  'CL_UPDATE_UPLOAD_ERROR_TOAST',
  withPayloadType<{ toastId: string; numUploadErrors: number; uploadErrorMessage: string }>(),
);
export const uploadErrorToastClosed = createAction('CL_UPLOAD_ERROR_TOAST_CLOSED');
