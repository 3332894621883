import { ILogger, getObservabilityService } from '@integration-frontends/core';
import { injectable } from 'inversify';
import jwtDecode from 'jwt-decode';
import {
  AddAssetTagsResponseData,
  ApiFetchDataResponse,
  ApiListDataResponse,
  ApiSearchableAggregationsResponse,
  AssetDto,
  AssetOptions,
  AttachmentDto,
  AttachmentInputDto,
  AttachmentOptions,
  BrandfolderDto,
  BrandfolderOptions,
  CollectionDto,
  CollectionOptions,
  CreateCustomFieldKeyData,
  CustomFieldKeyDto,
  CustomFieldKeyResponseData,
  CustomFieldKeyResponseDatum,
  CustomFieldKeyValueDto,
  CustomFieldValueDto,
  CustomFieldValueOptions,
  CustomFieldValueResponseDto,
  GenericFileDto,
  LabelDto,
  Options,
  optionsToQueryString,
  OrganizationDto,
  OrganizationOptions,
  PermissionLevelDto,
  RemoveAssetsFromCollectionsBody,
  ResourceType,
  SearchFilterDto,
  SectionDto,
  SectionOptions,
  ShareManifestDto,
  TagDto,
  UserDto,
} from './model';
import * as _promiseRetry from 'promise-retry';
import { default as _rollupPromiseRetry } from 'promise-retry';
import { isEmpty } from 'ramda';
import { AuthenticationResponse } from '@integration-frontends/common/auth/core/model';
import { CLApiFiletypeAggregationsResponse, CLApiTagsAggregationsResponse } from './cl-model';

// using "* as" breaks the rollup build so we need to use this workaround
// more info: https://github.com/rollup/rollup/issues/1267
const promiseRetry = _rollupPromiseRetry || _promiseRetry;

export const BRANDFOLDER_API_TOKEN = 'BRANDFOLDER_API';
const RETRY_COUNT = 3;

const getRequestsInFlight = {};

export type GetOptions = Options & {
  batchRequests?: boolean;
};

@injectable()
export class BrandfolderApi {
  constructor(
    private baseUrl: string,
    private logger: ILogger,
    private refreshToken?: () => Promise<string>,
    private refreshAuthTokenPath?: string,
  ) {}

  async listOrganizations(
    apiKey: string,
    options?: OrganizationOptions,
  ): Promise<ApiListDataResponse<OrganizationDto>> {
    return await this.get(apiKey, `/v4/organizations`, options);
  }

  async fetchAsset(
    apiKey: string,
    assetId: string,
    options?: AssetOptions,
  ): Promise<ApiFetchDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/assets/${assetId}`, options);
  }

  protected async requestUploadUrl(apiKey: string): Promise<{
    upload_url: string;
    object_url: string;
  }> {
    return await this.get(apiKey, '/v4/upload_requests', { batchRequests: false });
  }

  async uploadFile(apiKey: string, file: File): Promise<{ objectUrl: string }> {
    const { object_url, upload_url } = await this.requestUploadUrl(apiKey);
    await fetch(upload_url, { method: 'PUT', body: file });
    return { objectUrl: object_url };
  }

  async fetchCollectionPermissionLevel(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiFetchDataResponse<PermissionLevelDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/permission_level`);
  }

  private generateCreateAssetBody(
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ) {
    return {
      data: {
        attributes: [
          {
            name,
            attachments,
          },
        ],
      },
      section_key: sectionId,
    };
  }

  private generateCreateExternalMediaBody(sectionId: string, name: string, url: string) {
    return {
      data: {
        attributes: [
          {
            data: {
              url,
            },
            description: '',
            name,
          },
        ],
      },
      section_key: sectionId,
    };
  }

  async createExternalBrandfolderAsset(
    apiKey: string,
    brandfolderId: string,
    sectionId: string,
    externalMedia: {
      url: string;
      name: string;
    },
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    const body = this.generateCreateExternalMediaBody(
      sectionId,
      externalMedia.name,
      externalMedia.url,
    );
    const response = await this.post(apiKey, `/v4/brandfolders/${brandfolderId}/assets`, body);
    return response;
  }

  async createExternalCollectionAsset(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    externalMedia: {
      url: string;
      name: string;
    },
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    return await this.post(
      apiKey,
      `/v4/collections/${collectionId}/assets`,
      this.generateCreateExternalMediaBody(sectionId, externalMedia.name, externalMedia.url),
      undefined,
    );
  }

  async updateCollectionName(apiKey: string, collectionId: string, name: string) {
    return await this.put(apiKey, `/v4/collections/${collectionId}`, {
      data: {
        attributes: {
          name,
        },
      },
    });
  }

  async createBrandfolderAsset(
    apiKey: string,
    brandfolderId: string,
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ): Promise<ApiFetchDataResponse<GenericFileDto>> {
    return await this.post(
      apiKey,
      `/v4/brandfolders/${brandfolderId}/assets`,
      this.generateCreateAssetBody(sectionId, name, attachments),
    );
  }

  async createCollectionAsset(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    name: string,
    attachments: AttachmentInputDto[],
  ) {
    return await this.post(
      apiKey,
      `/v4/collections/${collectionId}/assets`,
      this.generateCreateAssetBody(sectionId, name, attachments),
      undefined,
    );
  }

  async listBrandfolders(
    apiKey: string,
    options?: BrandfolderOptions,
  ): Promise<ApiListDataResponse<BrandfolderDto>> {
    return await this.get(apiKey, `/v4/brandfolders`, options);
  }

  async fetchBrandfolder(
    apiKey: string,
    brandfolderId: string,
    options?: BrandfolderOptions,
  ): Promise<ApiFetchDataResponse<BrandfolderDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}`, options);
  }

  async listBrandfolderAssets(
    apiKey: string,
    brandfolderId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/assets`, options);
  }

  async listBrandfolderAttachments(
    apiKey: string,
    brandfolderId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/attachments`, options);
  }

  async listBrandfolderSections(
    apiKey: string,
    brandfolderId: string,
    options?: SectionOptions,
  ): Promise<ApiListDataResponse<SectionDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/sections`, options);
  }

  async getBrandfolderSearchableAggregations(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiSearchableAggregationsResponse> {
    const data = await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/searchable_things`);

    // map to valid payload if data is empty object
    return !data || isEmpty(data) ? { custom_fields: [], filetypes: [], tags: [] } : data;
  }

  async getBrandfolderTags(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<TagDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/tags`);
  }

  async addAssetTags(
    apiKey: string,
    assetIds: string | string[],
    tags: string[],
    collectionId?: string,
    locale?: string,
    source?: string,
  ): Promise<AddAssetTagsResponseData> {
    const data = {
      attributes: tags.map((tag) => {
        return { name: tag };
      }),
    };

    return await this.post(apiKey, `/v4/assets/${assetIds}/tags`, { data });
  }

  async listCustomFieldKeys(
    apiKey: string,
    brandfolderId: string,
  ): Promise<CustomFieldKeyResponseData> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/custom_field_keys`);
  }

  async createCustomFieldKeys(
    apiKey: string,
    brandfolderId: string,
    keys: CreateCustomFieldKeyData[],
  ): Promise<CustomFieldKeyResponseData> {
    return await this.post(apiKey, `/v4/brandfolders/${brandfolderId}/custom_field_keys`, {
      data: {
        attributes: keys,
      },
    });
  }

  async addCustomFieldsToAsset(
    apiKey: string,
    assetIds: string[],
    brandfolderId: string,
    customFields: CustomFieldKeyValueDto[],
  ): Promise<void> {
    const customFieldKeys: CustomFieldKeyResponseData = await this.listCustomFieldKeys(
      apiKey,
      brandfolderId,
    );

    assetIds.forEach((assetId: string) => {
      customFieldKeys.data.forEach((customFieldKey: CustomFieldKeyResponseDatum) => {
        customFields.forEach((customField: CustomFieldKeyValueDto) => {
          if (customFieldKey.attributes.name === Object.keys(customField)[0]) {
            this.post(apiKey, `/v4/custom_field_keys/${customFieldKey.id}/custom_field_values`, {
              data: [
                {
                  attributes: {
                    value: Object.values(customField)[0].join(', '),
                  },
                  relationships: {
                    asset: {
                      data: { type: 'assets', id: assetId },
                    },
                  },
                },
              ],
            });
          }
        });
      });
    });
  }

  async getBrandfolderCustomFieldsKeys(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<CustomFieldKeyDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/custom_field_keys`);
  }

  async getBrandfolderCustomFieldValues(
    apiKey: string,
    brandfolderId: string,
    customFieldKeyId: string,
  ): Promise<ApiListDataResponse<CustomFieldValueDto>> {
    return await this.get(
      apiKey,
      `/v4/brandfolders/${brandfolderId}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
    );
  }

  async getBrandfolderCustomFieldValuesOptions(
    apiKey: string,
    brandfolderId: string,
    customFieldKeyId: string,
  ): Promise<CustomFieldValueOptions> {
    return await this.get(
      apiKey,
      `/v4/brandfolders/${brandfolderId}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
    );
  }

  async getBrandfolderAssetShareUrl(
    apiKey: string,
    brandfolderId: string,
    assetId: string,
  ): Promise<ApiFetchDataResponse<ShareManifestDto>> {
    return await this.post(apiKey, `/v4/brandfolders/${brandfolderId}/share_manifests`, {
      data: {
        attributes: {
          asset_keys: [assetId],
        },
      },
    });
  }

  async getBrandfolderLabels(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<LabelDto>> {
    return await this.get(apiKey, `/v4/brandfolders/${brandfolderId}/labels`);
  }

  async listCollections(
    apiKey: string,
    options?: CollectionOptions,
  ): Promise<ApiListDataResponse<CollectionDto>> {
    return await this.get(apiKey, `/v4/collections`, options);
  }

  async fetchCollection(
    apiKey: string,
    collectionId: string,
    options?: CollectionOptions,
  ): Promise<ApiFetchDataResponse<CollectionDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}`, options);
  }

  async listCollectionAssets(
    apiKey: string,
    collectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/assets`, options);
  }

  async listCollectionSectionAssets(
    apiKey: string,
    collectionId: string,
    sectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(
      apiKey,
      `/v4/collections/${collectionId}/sections/${sectionId}/assets`,
      options,
    );
  }

  async listCollectionAttachments(
    apiKey: string,
    collectionId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/attachments`, options);
  }

  async listCollectionSections(
    apiKey: string,
    collectionId: string,
    options: SectionOptions,
  ): Promise<ApiListDataResponse<SectionDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/sections`, options);
  }

  async listCollectionSectionsPrivate(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<SectionDto>> {
    return await this.get(
      apiKey,
      `/v4/private/smartsheet/content-library/api/collections/${collectionId}/sections`,
    );
  }

  async getCollectionSearchableAggregations(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiSearchableAggregationsResponse> {
    const data = await this.get(apiKey, `/v4/collections/${collectionId}/searchable_things`);

    // map to valid payload if data is empty object
    return !data || isEmpty(data) ? { custom_fields: [], filetypes: [], tags: [] } : data;
  }

  async getCollectionTags(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<TagDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/tags`);
  }

  async getCollectionCustomFieldKeys(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<CustomFieldKeyDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/custom_field_keys`);
  }

  async getCollectionCustomFieldValues(
    apiKey: string,
    collectionId: string,
    customFieldKeyId: string,
  ): Promise<CustomFieldValueResponseDto> {
    return await this.get(
      apiKey,
      `/v4/collections/${collectionId}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
    );
  }

  async getCollectionCustomFieldValuesOptions(
    apiKey: string,
    collectionId: string,
    customFieldKeyId: string,
  ): Promise<CustomFieldValueOptions> {
    return await this.get(
      apiKey,
      `/v4/collections/${collectionId}/custom_field_keys/${customFieldKeyId}/custom_field_values`,
    );
  }

  async getCollectionAssetShareUrl(
    apiKey: string,
    collectionId: string,
    assetId: string,
  ): Promise<ApiFetchDataResponse<ShareManifestDto>> {
    return await this.post(apiKey, `/v4/collections/${collectionId}/share_manifests`, {
      data: {
        attributes: {
          asset_keys: [assetId],
        },
      },
    });
  }

  async getCollectionLabels(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<LabelDto>> {
    return await this.get(apiKey, `/v4/collections/${collectionId}/labels`);
  }

  // This endpoint should just be used by the smartsheet app only
  async removeAssetsFromCollections(
    apiKey: string,
    collectionId: string,
    body: RemoveAssetsFromCollectionsBody,
  ): Promise<{ result: string }> {
    return await this.delete(
      apiKey,
      `/v4/private/smartsheet/content-library/api/collections/${collectionId}/assets`,
      body,
    );
  }

  async listSectionAssets(
    apiKey: string,
    sectionId: string,
    options?: AssetOptions,
  ): Promise<ApiListDataResponse<AssetDto>> {
    return await this.get(apiKey, `/v4/sections/${sectionId}/assets`, options);
  }

  async fetchAttachment(
    apiKey: string,
    attachmentId: string,
    options?: AttachmentOptions,
  ): Promise<ApiFetchDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/attachments/${attachmentId}`, options);
  }

  async listAssetAttachments(
    apiKey: string,
    assetId: string,
    options?: AttachmentOptions,
  ): Promise<ApiListDataResponse<AttachmentDto>> {
    return await this.get(apiKey, `/v4/assets/${assetId}/attachments`, options);
  }

  async listBrandfolderSearchFilters(
    apiKey: string,
    brandfolderId: string,
  ): Promise<ApiListDataResponse<SearchFilterDto>> {
    return await this.get(apiKey, `/v3/brandfolders/${brandfolderId}/search_filters`);
  }

  async listCollectionSearchFilters(
    apiKey: string,
    collectionId: string,
  ): Promise<ApiListDataResponse<SearchFilterDto>> {
    return await this.get(apiKey, `/v3/collections/${collectionId}/search_filters`);
  }

  async refreshAuth(): Promise<AuthenticationResponse> {
    const res = await fetch(this.refreshAuthTokenPath);
    const data: AuthenticationResponse = await res.json();
    return data;
  }

  async whoAmI(apiKey: string): Promise<ApiFetchDataResponse<UserDto>> {
    // TEMP - the internal "get" method checks and refreshes the API key/token if needed, which we don't
    // want here because it will break some of the assumptions made by consumers of the lib when calling this
    // particular endpoint.
    // Eventually we'll want to extract the API key/token refresh logic and put it outside of this context
    // so that any persisted tokens can be updated with the new one.
    // ~PP

    try {
      const response = await fetch(`${this.baseUrl}/v4/users/whoami`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
      });

      return await response.json();
    } catch (e) {
      getObservabilityService()?.addError(e);
      return null;
    }
  }

  async downloadAssetAttachments(
    assetId: string,
    resourceName: string,
    resourceId: string,
    resourceType: ResourceType.BRANDFOLDER | ResourceType.COLLECTION,
  ) {
    return await fetch(
      `https://www.brandfolder.com/${resourceName.toLowerCase()}/zip/asset/${assetId}?resource_key=${resourceId}&resource_type=${
        resourceType === ResourceType.BRANDFOLDER ? 'Brandfolder' : 'Collection'
      }`,
      {
        mode: 'no-cors',
        credentials: 'same-origin',
      },
    );
  }

  async bulkDownloadAssets(apiKey: string, assetIds: string[], name?: string) {
    return await this.post(apiKey, '/v3/assets/download?queue_priority=high', {
      asset_keys: assetIds,
      name,
    });
  }

  async saveUploadPreferences(
    apiKey: string,
    organizationId: string,
    brandfolderId: string,
    sectionId: string,
    collectionId: string = null,
  ) {
    return await this.post(apiKey, '/v1/contentsync/panelui/upload_preferences', {
      data: {
        attributes: {
          organization_key: organizationId,
          brandfolder_key: brandfolderId,
          section_key: sectionId,
          collection_key: collectionId,
        },
      },
    });
  }

  async getUploadPreferences(apiKey: string) {
    return await this.get(apiKey, '/v1/contentsync/panelui/upload_preferences');
  }

  protected async get(
    apiKey: string,
    path: string,
    options: GetOptions = {},
    init: RequestInit = {},
  ) {
    const { batchRequests = true } = options;
    const callString = `${apiKey}${path}${optionsToQueryString(options)}`;

    function fetchData(this: BrandfolderApi) {
      return promiseRetry(async (retry, counter) => {
        function handleRetry() {
          if (counter <= RETRY_COUNT) {
            retry();
          } else {
            return null;
          }
        }

        try {
          const data = await this.fetchFromApi(apiKey, `${path}${optionsToQueryString(options)}`, {
            ...init,
            method: 'GET',
          });

          return data;
        } catch (e) {
          return handleRetry();
        }
      });
    }

    if (batchRequests) {
      if (!getRequestsInFlight[callString]) {
        const dataPromise = fetchData.bind(this)();
        getRequestsInFlight[callString] = dataPromise;
        dataPromise.then(() => (getRequestsInFlight[callString] = false));
      }
      return await getRequestsInFlight[callString];
    } else {
      return await fetchData.bind(this)();
    }
  }

  protected async post(
    apiKey: string,
    path: string,
    body: any,
    init: RequestInit = {},
    includeBodyInUrl = false,
  ) {
    const response = await this.fetchFromApi(
      apiKey,
      `${path}${includeBodyInUrl ? optionsToQueryString(body) : ''}`,
      {
        ...init,
        method: 'POST',
        body: JSON.stringify(body),
      },
    );
    handleApiErrors(response);
    return response;
  }

  private async put(apiKey: string, path: string, body: any, init: RequestInit = {}) {
    const response = await this.fetchFromApi(apiKey, `${path}`, {
      ...init,
      method: 'PUT',
      body: JSON.stringify(body),
    });
    handleApiErrors(response);
    return response;
  }

  private async delete(apiKey: string, path: string, body: any, init: RequestInit = {}) {
    const response = await this.fetchFromApi(apiKey, `${path}`, {
      ...init,
      method: 'DELETE',
      body: JSON.stringify(body),
    });
    handleApiErrors(response);
    return response;
  }

  private async fetchFromApi(apiKey: string, path: string, init: RequestInit = {}) {
    try {
      if (isExpired(apiKey) && this.refreshToken) {
        apiKey = await this.refreshToken();
      }

      const response = await fetch(`${this.baseUrl}${path}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        ...init,
      });
      if (isError401(response)) {
        if (this.refreshToken) {
          apiKey = await this.refreshToken();
        } else {
          // TODO: improve handling of the "unauthorized" case
          // https://app.smartsheet.com/sheets/P8VGqVPRqr2CG2pqGgpM76RQvHFjvM4fmwGWgGx1?rowId=46199533463428
          return null;
        }
        return await this.fetchFromApi(apiKey, path, init);
      } else if (
        (path.includes('filetype_aggregations') && isStatus202(response)) ||
        (path.includes('tag_aggregations') && isStatus202(response))
      ) {
        return await this.fetchWithRetry(apiKey, path, init);
      } else {
        return await response.json();
      }
    } catch (e) {
      getObservabilityService()?.addError(e);
      throw e;
    }
  }

  private async fetchWithRetry(apiKey: string, path: string, init: RequestInit = {}) {
    const maxRetries = 6; //2^7 128s max;
    let retryCount = 0;
    let delay = 1;

    while (retryCount <= maxRetries) {
      const response = await fetch(`${this.baseUrl}${path}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        },
        ...init,
      });

      if (response.status === 202) {
        await this.delay(delay * 1000);
        delay *= 2;
        retryCount++;
      } else {
        return await response.json();
      }
    }
  }

  private delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async searchFilterableTags(apiKey: string, collectionId: string, search: string) {
    return Promise.resolve([]);
  }

  // NOTE: only exists for File library (Collection) as of Sept 5, 2024
  async getCollectionTagAggregations(
    apiKey: string,
    collectionId: string,
  ): Promise<CLApiTagsAggregationsResponse> {
    return Promise.resolve({ tags: [] });
  }

  // NOTE: only exists for File library (Collection) as of Sept 5, 2024
  async getCollectionFiletypeAggregations(
    apiKey: string,
    collectionId: string,
  ): Promise<CLApiFiletypeAggregationsResponse> {
    return Promise.resolve({ filetypes: [] });
  }

  // NOTE: only exists for File library (Collection) as of Sept 23, 2024
  getAssetAttachment = async (
    apiKey: string,
    assetId: string,
  ): Promise<ApiFetchDataResponse<AttachmentDto>> => {
    return Promise.resolve(null);
  };
}

function isError401(response): boolean {
  return response.status === 401;
}

function isStatus202(response): boolean {
  return response.status === 202;
}
interface decodedApiKeyProperties {
  exp?: number;
}

function getDecodedApiKey(key: string): decodedApiKeyProperties {
  return jwtDecode(key);
}

function isExpired(key: string): boolean {
  try {
    const { exp } = getDecodedApiKey(key);
    if (exp && exp - new Date().getTime() / 1000 < 60) {
      return true;
    }
    return false;
  } catch (e) {
    // if decoding fails then we're dealing with an API key, not an oauth token
    return false;
  }
}

function handleApiErrors(response) {
  handleErrors(response.errors);
}

function handleErrors(errors) {
  if (errorsExist(errors)) {
    throw errors;
  }
}

function errorsExist(errors) {
  try {
    return Object.keys(errors).length > 0;
  } catch (error) {
    return false;
  }
}
