import { BFHR } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

export function ActionsFlexContainer({
  children,
  className,
  style,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('bg-bf-white', className)}
      style={{ flex: '0 0 75px', ...style }}
      {...props}
    >
      <BFHR />
      {children}
    </div>
  );
}

export function TitleFlexContainer({
  className,
  style,
  ...props
}: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={classNames(
        'flex items-center justify-center block border-b border-bf-lightish-gray',
      )}
      style={{ flex: '0 0 70px' }}
      {...props}
    />
  );
}

export function ContentBody({ style, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div style={{ paddingLeft: 30, paddingRight: 30, ...style }} {...props} />;
}
