import { environment } from '@integration-frontends/apps/integration-panel-ui/src/environments/environment';
import { loadSegment } from '@integration-frontends/common/analytics';
import { init, selectInitSuccess } from '@integration-frontends/common/app';
import { OauthCallbackCode, SetApiKey } from '@integration-frontends/common/auth/ui';
import { BFPanel, Loader } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  IViewConfigsProvider,
  VIEW_CONFIGS_PROVIDER_TOKEN,
} from '@integration-frontends/core/ui/view-configs-provider';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import React, { Suspense, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, unstable_HistoryRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { dynamicActivate } from '../../../../i18n';
import { AttachmentUploader } from '../../../../libs/integration/ui/src/lib/attachment-uploader';
import { SelectAttachment } from '../../../common/src';
import { Header } from '../../../common/src/ui/header';
import { HOST_URL, OAUTH_BASE_URL, OAUTH_CLIENT_ID } from '../config';
import './app.scss';
import { getParam } from './common/utilities';
import { StoreContext, StoreContextProvider, useStoreContext } from './store';

export enum Size {
  Fixed = 'fixed',
  Full = 'full',
}

export function ConnectedApp() {
  const viewConfigsProvider: IViewConfigsProvider = DI_CONTAINER.get(VIEW_CONFIGS_PROVIDER_TOKEN);
  const initSuccess = useSelector(selectInitSuccess);
  const dispatch = useDispatch();
  const { history, persistor } = useStoreContext();

  const appName = (
    new URLSearchParams(window.location.search).get('appName') || 'panel-ui'
  ).toLowerCase();

  const headerBackgroundUrl = new URLSearchParams(window.location.search).get(
    'headerbackgroundurl',
  );

  const loader = (
    <div className="flex h-full w-full items-center justify-center">
      <Loader />
    </div>
  );

  useEffect(() => {
    dispatch(init({ name: `integration-${appName}`, headerBackgroundUrl, history, persistor }));
    loadSegment(environment);
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        enableMultiContainer
        containerId="topLevel"
        className="top-level-toast-container"
      />

      <BFPanel
        className="flex flex-col overflow-y-hidden"
        style={{
          ...viewConfigsProvider.getViewDimensions(),
        }}
      >
        <Suspense fallback={loader}>
          {!initSuccess ? (
            loader
          ) : (
            <Routes>
              <Route path="/" />
              <Route
                path="/getting-started"
                element={
                  <div id="getting-started-container">
                    <SetApiKey
                      hostUrl={HOST_URL}
                      oauthClientId={OAUTH_CLIENT_ID}
                      oauthBaseUrl={OAUTH_BASE_URL}
                    />
                  </div>
                }
              />
              <Route path="/callback/code" element={<OauthCallbackCode />} />
              <Route path="/select-attachment/*" element={<SelectAttachment />} />
              <Route
                path="/upload"
                element={
                  <>
                    <Header />
                    <AttachmentUploader />
                  </>
                }
              />
              <Route path="*" element={<Navigate to={'/select-attachment'} replace />} />
            </Routes>
          )}
        </Suspense>
      </BFPanel>
    </>
  );
}

export function App() {
  useEffect(() => {
    dynamicActivate('en');
  }, []);

  return (
    <StoreContextProvider>
      <StoreContext.Consumer>
        {({ store, history }) => {
          return (
            store && (
              <div>
                <I18nProvider i18n={i18n}>
                  <Provider store={store}>
                    <Router history={history}>
                      <ConnectedApp />
                    </Router>
                  </Provider>
                </I18nProvider>
              </div>
            )
          );
        }}
      </StoreContext.Consumer>
    </StoreContextProvider>
  );
}

export default App;
