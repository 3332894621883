import {
  AttachmentActionContainerBaseProps,
  IAttachmentActionProvider,
} from '@integration-frontends/integration/ui';
import { COPY_MODE } from '../common/utilities';
import React from 'react';
import { CopyAttachmentActionContainer } from '@integration-frontends/apps/common/ui/attachment-actions';

export class AttachmentActionsProviderPanelUIStrategy implements IAttachmentActionProvider {
  getActionContainers(): React.FunctionComponent<AttachmentActionContainerBaseProps>[] {
    return COPY_MODE ? [CopyAttachmentActionContainer] : [];
  }
}
