import { put, select, takeEvery } from 'typed-redux-saga';
import {
  uppyUploadError,
  uppyUploadFilesAdded,
  uppyUploadFilesAddedFailure,
  uppyUploadFilesAddedSuccess,
} from '../../actions';
import { uploadAssetsSelectors } from '../../../../selectors';
import { ASSET_REPO_TOKEN, IAssetRepo } from '@integration-frontends/integration/core/model';
import { DI_CONTAINER } from '@integration-frontends/core';
import { diContainer } from '@smartsheet/cufflink';
import { LoggingStateService, loggingStateServiceKey } from '@smartsheet/logging-state-service';
import { push } from 'redux-first-history';
import { getAppRoutes } from '@integration-frontends/core/app-routes';

export function* uppyUploadFilesAddedEffects() {
  yield takeEvery(uppyUploadFilesAdded, handler);
}

const handler = function* (action: ReturnType<typeof uppyUploadFilesAdded>) {
  const { files, uppy } = action.payload;
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const collectionId = yield select(uploadAssetsSelectors.selectedCollectionId);
  const fileSizeSum = files.reduce((acc, file) => acc + file.size, 0);

  const remainingFileStorageResponse = yield assetRepo.listCollectionStorageRemainingBase(
    collectionId,
  );

  if (remainingFileStorageResponse.data.remaining === null) {
    yield handleRemainingStorageFailed('Failed to get remaining storage.', uppy);
  } else if (remainingFileStorageResponse.data.remaining < fileSizeSum) {
    yield handleUploadExceedsStorageLimit(uppy);
  } else {
    yield put(uppyUploadFilesAddedSuccess());
  }
};

function* handleUploadExceedsStorageLimit(uppy) {
  if (uppy) {
    uppy.cancelAll();
  }
  yield put(uppyUploadFilesAddedFailure());
  yield put(uppyUploadError({ uploadErrorType: 'uploadExceedsPlanStorageLimitError' }));
}

function* handleRemainingStorageFailed(error, uppy) {
  const loggingStateService = diContainer.get<LoggingStateService>(loggingStateServiceKey.name);

  yield put(uppyUploadFilesAddedFailure());
  if (uppy) {
    uppy.cancelAll();
  }
  loggingStateService.error(
    'Error with remaining file storage on upload in File Library',
    new Error(error),
    undefined,
    'content-library',
    undefined,
    'content-library',
  );
  yield put(push(`${getAppRoutes().HOME}?error=true`));
}
