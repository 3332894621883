import { uploadAssetsSelectors } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageContainer, PageScrollContainer } from '../common/layout/page';
import { IntegrationPageLoader } from '../common/page-loader/page-loader';
import { TitleFlexContainer } from './common';
import { UploadForm } from './upload-form';
import { UploadSuccess } from './upload-success';
import { Trans } from '@lingui/macro';

export function AttachmentUploader() {
  const success = useSelector(uploadAssetsSelectors.success);
  const loading = useSelector(uploadAssetsSelectors.loading);

  return (
    <PageContainer>
      <PageScrollContainer className="h-full">
        <div className="flex min-h-0 flex-grow flex-col">
          {loading ? (
            <IntegrationPageLoader />
          ) : (
            <>
              <TitleFlexContainer>
                <Trans>Title</Trans>
              </TitleFlexContainer>
              {success ? <UploadSuccess /> : <UploadForm />}
            </>
          )}
        </div>
      </PageScrollContainer>
    </PageContainer>
  );
}
