import { put, takeEvery } from 'redux-saga/effects';
import { sendSegmentAction } from '../../../../libs/common/analytics/src';
import { placeAttachment } from '../../../../libs/integration/core/application/src';
import { AttachmentWithDto } from '../../../../libs/integration/infrastructure/src/lib/isomorphic';
import { sendMessage } from '../utils/send-message';

const handler = function* (action: ReturnType<typeof placeAttachment>) {
  console.log(action.payload.attachment, 'attachment');
  const { url, dto, mimetype } = action.payload.attachment as AttachmentWithDto;
  let dimensions = action.payload.attachment.dimensions;
  let cdnUrl = url;

  if (mimetype === 'image/svg+xml' || dto.attributes.extension === 'svg') {
    cdnUrl = url.replace('?auto=webp', '');
  }
  
  let payloadDto = {
    ...dto,
    attributes: {
      ...dto.attributes,
      cdn_url: cdnUrl,
      height: dimensions?.height,
      width: dimensions?.width
    }
  };
  
  sendMessage({
    event: 'selectedAttachment',
    payload: {
      ...payloadDto,
      ...payloadDto.attributes,
      cdn_url: cdnUrl,
      mimetype,
    },
  });
  yield put(sendSegmentAction({ event: `placeAttachment` }));
};

export function* placeAttachmentWorkflow() {
  yield takeEvery(placeAttachment, handler);
}
