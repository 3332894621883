/**
 * Copied from legacy repo
 * TODO: need to refactor this ~PP
 */

export const getParam = (name) => {
  // eslint-disable-next-line no-restricted-globals
  var param = decodeURI((RegExp(name + '=' + '(.+?)(&|$)').exec(location.search) || [null])[1]);
  return param == 'undefined' ? undefined : param;
};

export const size = getParam('size');
export const openLinks = getParam('openLinks') !== 'false';
console.log('openLinks:', openLinks);
console.log(typeof openLinks);

export const attachmentFields = (getParam('attachment_fields') || '').split(',');
console.log('attachmentFields:', attachmentFields);

const getAllowedExtensions = () =>
  (getParam('allowedExtensions') || '')
    .split(',')
    .filter(Boolean)
    .map((ext) => ext.trim().toUpperCase());

console.log('allowedExtensions:', getAllowedExtensions);

export const isAllowedExtension = (ext) =>
  !getAllowedExtensions().length ||
  getAllowedExtensions()
    .map((ext) => ext.toLocaleUpperCase())
    .includes(ext?.toLocaleUpperCase());

export const PERSIST_ROUTE = !!getParam('persistRoute');
console.log('Persist route : ' + PERSIST_ROUTE);

export const COPY_MODE = !!getParam('copyMode');
console.log('Copy mode : ' + COPY_MODE);

export const RESPONSIVE = !!getParam('responsive');
console.log('Responsive : ' + RESPONSIVE);

// Figma utility -- Temporary utility created for Figma -- move into Figma app once within frontends repo
export const IS_FIGMA = !!getParam('isFigma');
console.log('Figma Application :' + IS_FIGMA);
