import { placeAttachmentWorkflow } from '@integration-frontends/apps/common/workflows';
import { all, fork } from 'typed-redux-saga';
import { createPageLoadErrorRedirectEffect } from '../../../../common/src/errors';
import { authWorkflow } from './auth-workflow';
import { backToHomeEffects } from './back-to-home';
import { externalLinkClickedEffects } from './external-link-clicked-effects';
import { initiateFigmaUploadEffects } from './figma/figma-upload-workflow';
import { initWorkflow } from './init-workflow';
import { routePersistenceEffects } from './route-persistence';
import { getObservabilityService } from '@integration-frontends/core';
import { call } from 'ramda';

export function* rootWorkflow() {
  try {
    yield initWorkflow();
    yield all([
      fork(authWorkflow),
      fork(backToHomeEffects),
      fork(externalLinkClickedEffects),
      fork(placeAttachmentWorkflow),
      fork(routePersistenceEffects),
      fork(initiateFigmaUploadEffects),
      fork(createPageLoadErrorRedirectEffect('/select-attachment')),
    ]);
  } catch (e) {
    yield call(getObservabilityService().addError, e);
  }
}
