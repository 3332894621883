import { DI_CONTAINER } from '../di-container';

export const APP_ROUTES_TOKEN = 'APP_ROUTES_TOKEN';

export type GetAppRoutes = () => {
  HOME: string;
};

export const ROUTES = {
  // Routes for Smartsheet Content Library Cuff Library
  CUFF_HOME: '/r/workspaces/:directId/file-library',

  // Routes for Smartsheet Content Library App
  APP_HOME: '/',
  APP_LOGIN: '/login',
};

export const getAppRoutes: GetAppRoutes = () => {
  const getAppRoutes: GetAppRoutes = DI_CONTAINER.get(APP_ROUTES_TOKEN);
  return getAppRoutes();
};
