import { IViewConfigsProvider } from '@integration-frontends/core/ui/view-configs-provider';
import { injectable } from 'inversify';
import { size as sizeFromUrl } from '../common/utilities';

@injectable()
export class ViewConfigsProviderPanelUiStrategy implements IViewConfigsProvider {
  constructor(private size = sizeFromUrl) {}
  getViewDimensions(): { width: string; height: string } {
    return this.size === 'full'
      ? { width: '100%', height: '100vh' }
      : { width: '415px', height: '640px' };
  }
}
