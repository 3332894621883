import { createStore } from '@integration-frontends/apps/integration-panel-ui/src/app/store/store';
import { Store } from '@reduxjs/toolkit';
import { History } from 'history';
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import { Persistor } from 'redux-persist';

export interface StoreContext {
  store: Store<any>;
  history: History;
  persistor: Persistor;
}
export const StoreContext = createContext<StoreContext>({
  store: null,
  history: null,
  persistor: null,
});

export const StoreContextProvider = ({ children }: PropsWithChildren<any>) => {
  const [store, setStore] = useState(null);
  const [history, setHistory] = useState(null);
  const [persistor, setPersistor] = useState(null);

  useEffect(() => {
    const { history, store, persistor } = createStore();
    setStore(store);
    setHistory(history);
    setPersistor(persistor);
  }, []);

  return <StoreContext.Provider value={{ store, history, persistor }} children={children} />;
};
