import { put, takeEvery } from 'typed-redux-saga';
import { createUploadErrorToast } from '../../actions';
import { createToast } from '@integration-frontends/common/notifications';

export function* createUploadErrorToastEffect() {
  yield takeEvery(createUploadErrorToast, handler);
}

const handler = function* (action: ReturnType<typeof createUploadErrorToast>) {
  const { toastId, uploadErrorMessage } = action.payload;

  yield put(
    createToast({
      toastContent: {
        contentText: uploadErrorMessage,
      },
      id: toastId,
      type: 'error',
    }),
  );
};
