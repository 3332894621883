import { AttachmentSelectorFeaturesService } from '@integration-frontends/integration/infrastructure/isomorphic';
import { AttachmentSelectorFeature } from '@integration-frontends/integration/ui';
import { COPY_MODE, RESPONSIVE } from '../common/utilities';

export class AttachmentSelectorFeatureServicePanelUiStrategy extends AttachmentSelectorFeaturesService {
  constructor() {
    const features = [
      AttachmentSelectorFeature.CdnEmbed,
      AttachmentSelectorFeature.PlacementOptions,
      ...(RESPONSIVE ? [AttachmentSelectorFeature.Responsive] : []),
      ...(COPY_MODE ? [AttachmentSelectorFeature.CopyMode] : []),
    ];

    super(features);
  }
}
