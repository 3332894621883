import { Brandfolder, Collection, Section } from '@integration-frontends/integration/core/model';
import { withPayloadType } from '@integration-frontends/common/utils/redux';
import { createAction } from '@reduxjs/toolkit';

export const init = createAction('INTEGRATION_PANEL_UI_INIT');
export const initSuccess = createAction('INTEGRATION_PANEL_UI_INIT_SUCCESS');
export const getStarted = createAction('INTEGRATION_PANEL_UI_GET_STARTED');

// Figma actions -- Temporary actions created for Figma -- move into Figma app once within frontends repo
export const initiateFigmaUpload = createAction(
  'INITIATE_FIGMA_UPLOAD',
  withPayloadType<{ parentContainer: Brandfolder | Collection; section: Section }>(),
);