import { sendMessage } from '@integration-frontends/apps/common/utils';
import { sendSegmentAction } from '@integration-frontends/common/analytics';
import { put, takeEvery } from 'redux-saga/effects';
import { initiateFigmaUpload } from '../../actions';

const handler = function* (action: ReturnType<typeof initiateFigmaUpload>) {
  sendMessage({
    event: 'initFigmaUpload',
    payload: {
      parentContainer: action.payload.parentContainer,
      section: action.payload.section,
    },
  });
  yield put(sendSegmentAction({ event: 'uploadSuccess' }));
};

export function* initiateFigmaUploadEffects() {
  yield takeEvery(initiateFigmaUpload, handler);
}
