import { AuthUiModule } from '@integration-frontends/common/auth/ui';
import { IntegrationUiModule } from '@integration-frontends/integration/ui';
import { all, fork } from 'redux-saga/effects';
import { rootWorkflow } from '../workflows';
import { appRootReducer } from '@integration-frontends/common/app';
import storage from 'redux-persist/lib/storage';
import {
  notificationEffects,
  notificationsRootReducer,
} from '@integration-frontends/common/notifications';
import { createAppStore } from '@integration-frontends/apps/common/store/create-app-store';
import { createBrowserHistory } from 'history';

// TODO add configs ~PP
const ENVIRONMENT = 'development';

const rootSaga = function* () {
  yield all([
    fork(rootWorkflow),
    fork(IntegrationUiModule.getEffects()),
    fork(notificationEffects),
  ]);
};

export const createStore = () => {
  return createAppStore({
    environment: ENVIRONMENT,
    reducers: {
      ...notificationsRootReducer,
      ...appRootReducer,
      ...AuthUiModule.getRootReducer(),
      ...IntegrationUiModule.getRootReducer(),
    },
    rootSaga,
    persistConfig: {
      enabled: true,
      storage,
    },
    history: createBrowserHistory(),
  });
};
