import { createReduxHistoryContext } from 'redux-first-history';
import { History } from 'history';
import { createLogger } from 'redux-logger';
import createSagaMiddleware, { Saga } from 'redux-saga';
import {
  Action,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Middleware,
  ReducersMapObject,
} from '@reduxjs/toolkit';
import { logout } from '../../../../libs/common/auth/core/application/src';
import { BFStorage } from '../../../../libs/core/src/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import ReduxThunk from 'redux-thunk';
import { segmentMiddleware } from '../../../../libs/common/analytics/src';

export interface CreateStoreProps {
  basename?: string;
  environment: string;
  reducers: ReducersMapObject;
  rootSaga: Saga;
  persistConfig?: CreateStorePersistConfig;
  history: History;
}

export interface CreateStorePersistConfig {
  enabled: boolean;
  storage?: BFStorage;
  blacklist?: string[];
}

export function createAppStore({
  environment,
  reducers,
  rootSaga,
  persistConfig = { enabled: false },
  history,
  basename,
}: CreateStoreProps) {
  const middlewares: Middleware[] = [];

  middlewares.push(ReduxThunk);
  //TODO: remove segment from repo
  middlewares.push(segmentMiddleware);

  // Redux history
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history,
    basename,
  });

  middlewares.push(routerMiddleware);

  // Redux logger
  const reduxLogger = createLogger();

  if (environment === 'development') {
    middlewares.push(reduxLogger as Middleware);
  }

  // Redux saga
  const sagaMiddleware = createSagaMiddleware();

  middlewares.push(sagaMiddleware);

  // Setup store
  const appReducer = combineReducers({
    router: routerReducer,
    ...reducers,
  });

  let rootReducer = function (state: any, action: Action) {
    // clear state on logout
    if (action.type === logout.type) {
      return appReducer(undefined, action);
    }

    return appReducer(state, action);
  };

  if (persistConfig.enabled) {
    rootReducer = persistReducer(
      {
        key: 'root',
        storage: persistConfig.storage || storage,
        version: __webpack_hash__,
        migrate: async (state, currentVersion) => {
          if (state._persist.version !== currentVersion) {
            console.log('Code version has changed, invalidating cached data');
            // if code version has changed then we can't use persisted state
            return null;
          }

          return state;
        },
        blacklist: persistConfig.blacklist || [],
      },
      rootReducer,
    );
  }

  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

  // Need to run this after store has been created
  sagaMiddleware.run(rootSaga);

  return {
    history: createReduxHistory(store),
    persistor: persistConfig.enabled
      ? persistStore(store, { manualPersist: true } as unknown)
      : undefined,
    store,
  };
}
