import {
  getMediaTypesExtensions,
  IMediaTypeSupportService,
  MediaType,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { isAllowedExtension } from '../common/utilities';

@injectable()
export class MediaTypeSupportServicePanelUiStrategy implements IMediaTypeSupportService {
  constructor(private exportableMediaTypes: MediaType[]) {}

  getAllowedMediaTypes = (): MediaType[] | null => {
    return null;
  };

  getExportableMediaTypes = (): MediaType[] => {
    return this.exportableMediaTypes.filter((mediaType) => this.isSupported(mediaType));
  };

  getPlacementMediaTypes = (): MediaType[] => {
    return this.exportableMediaTypes.filter((mediaType) => this.isSupported(mediaType));
  };

  isSupported = (mediaType: MediaType): boolean => {
    return !!getMediaTypesExtensions([mediaType]).find(isAllowedExtension);
  };
}
