import { toast } from 'react-toastify';

export const displaySuccessToast = () => {
  toast.success('Upload success!', {
    theme: 'colored',
    className: 'figma-upload-success-toast',
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    containerId: 'topLevel',
  });
};
