import {
  CANCEL_KEY,
  FILES_KEY,
  FILE_KEY,
  SAVE_KEY,
} from '@integration-frontends/integration/ui/attachment-selector/i18n';
import { useAttachmentSelectorNavigation } from '@integration-frontends/integration/ui/attachment-selector/navigation';
import { selectName } from '@integration-frontends/common/app';
import {
  BFButton,
  BFIconButton,
  BFInput,
  ButtonType,
  IconEdit,
  IconTrash,
  Loader,
} from '@integration-frontends/common/ui';
import {
  searchAssetsSelectors,
  upload,
  uploadAssetsSelectors,
  uploadMore,
} from '@integration-frontends/integration/core/application';
import { Brandfolder, Collection, Section } from '@integration-frontends/integration/core/model';
import {
  INTEGRATION_COMMON_NAMESPACE,
  UPLOAD_KEY,
} from '@integration-frontends/integration/ui/common/i18n';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FigmaUploadHeader } from '../figma-upload-header/figma-upload-header';
import 'react-toastify/dist/ReactToastify.css';
import './figma-upload.scss';
import { displaySuccessToast } from '@integration-frontends/integration/ui/attachment-selector/components/section-upload-page/upload-success-toast';
import { ReactElementLike } from 'prop-types';

const FigmaUpload = () => {
  const { t } = useTranslation(INTEGRATION_COMMON_NAMESPACE);
  const navigation = useAttachmentSelectorNavigation();
  const dispatch = useDispatch();
  const appName = useSelector(selectName);
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const sortOptions = useSelector(searchAssetsSelectors.sortOptions);
  const [attachments, setAttachments] = useState({});
  const [parentContainer, setParentContainer] = useState<Brandfolder | Collection>(null);
  const [section, setSection] = useState<Section>(null);
  const [nameEditsActive, setNameEditsActive] = useState({});
  const [nameInputs, setNameInputs] = useState({});
  const uploading = useSelector(uploadAssetsSelectors.uploading);
  const uploadSuccess = useSelector(uploadAssetsSelectors.success);

  const updateAttachments = (selections) => {
    const attachmentsMap = selections.reduce((map, selection, i) => {
      const { name, imageData } = selection;
      const blob = new Blob([imageData], { type: 'image/png' });
      const urlCreator = window.URL || window.webkitURL;
      const url = urlCreator.createObjectURL(blob);
      map[i] = {
        name: name,
        imgUrl: url,
        nameEditActive: false,
      };
      return map;
    }, {});

    setAttachments(attachmentsMap);
  };

  //TODO: Move this into initiate figma workflow and handle business logic within workflow
  const receiveFigmaImageData = (event) => {
    if (event.data.event === 'figmaUpload') {
      const { selections, parentContainer, section } = event.data.payload;

      updateAttachments(selections);
      setParentContainer(parentContainer);
      setSection(section);

      window.removeEventListener('message', receiveFigmaImageData);
    }
  };

  const removeAttachmentFromUpload = (id: string) => {
    const localCopy = attachments;
    delete localCopy[id];

    setAttachments({ ...localCopy });
  };

  const handleNameEditActive = (id: string) => {
    setNameEditsActive({ ...nameEditsActive, [id]: true });
    setNameInputs({ ...nameInputs, [id]: attachments[id].name });
  };

  const handleNameEditInactive = (id: string) => {
    const localCopy = nameEditsActive;
    delete localCopy[id];

    setNameEditsActive({ ...localCopy });
  };

  const handleNameEditChange = (e, id: string) => {
    const nameEdit = e.target.value;

    setNameInputs({ ...nameInputs, [id]: nameEdit });
  };

  const handleNameEditSaved = (id: string) => {
    const nameEdit = nameInputs[id];
    attachments[id].name = nameEdit;
    handleNameEditInactive(id);
  };

  const handleUploadClicked = () => {
    const files = Object.keys(attachments).map((id) => {
      const fileName = attachments[id].name;
      const url = attachments[id].imgUrl;
      return { name: fileName, url: url };
    });

    dispatch(
      upload({
        container: parentContainer,
        sectionId: section.id,
        assets: files,
        source: `${appName}-panel`,
      }),
    );
  };

  const attachmentEditForms: ReactElement[] = Object.keys(attachments).map((id, i) => {
    const lastElem = Object.keys(attachments).length - 1;

    return (
      <div
        className={
          i === lastElem ? `figma-upload-card figma-upload-card__last` : `figma-upload-card`
        }
        key={id}
      >
        <div className="file-info-container">
          <img
            alt="Thumbnail preview for upload"
            className="img-thumbnail"
            id={`img-thumbnail-${id}`}
            src={attachments[id].imgUrl}
          />
          {!nameEditsActive[id] && <p className="filename">{attachments[id].name}</p>}
          {nameEditsActive[id] && (
            <BFInput
              className="filename-input"
              id={`filename-input-${id}`}
              value={nameInputs[id]}
              onChange={(e) => handleNameEditChange(e, id)}
            />
          )}
        </div>
        <div className="buttons-container">
          {!nameEditsActive[id] && (
            <>
              <BFIconButton
                className="edit-icon-button"
                iconElement={(<IconEdit />) as ReactElementLike}
                onClick={() => handleNameEditActive(id)}
              />
              <BFIconButton
                className="trash-icon-button"
                iconElement={(<IconTrash />) as ReactElementLike}
                onClick={() => removeAttachmentFromUpload(id)}
              />
            </>
          )}
          {nameEditsActive[id] && (
            <>
              <BFButton className="save-button" onClick={() => handleNameEditSaved(id)}>
                {t(SAVE_KEY)}
              </BFButton>
              <BFButton
                className="cancel-button"
                buttonType={ButtonType.Secondary}
                onClick={() => handleNameEditInactive(id)}
              >
                {t(CANCEL_KEY)}
              </BFButton>
            </>
          )}
        </div>
      </div>
    );
  });

  const returnToShowPage = () => {
    navigation.goToShowPage(parentContainer.id, searchParams, sortOptions);
  };

  if (uploadSuccess) {
    returnToShowPage();
    displaySuccessToast();
    dispatch(uploadMore());
  }

  useEffect(() => {
    window.addEventListener('message', receiveFigmaImageData);
  }, []);

  return (
    <div className="flex h-full w-full flex-col" style={{ backgroundColor: 'white' }}>
      <FigmaUploadHeader container={parentContainer} />
      {!uploading && (
        <div className="figma-upload-form-container">
          {attachments && parentContainer && section ? (
            <>
              <div className="w-full">
                <h3 className="form-title">{`Upload selections to ${section?.name}`}</h3>
                <div className="figma-upload-cards-container">{attachmentEditForms}</div>
              </div>
              <div className="upload-button-container">
                <BFButton className="upload-button" onClick={() => handleUploadClicked()}>
                  {Object.keys(attachments).length > 1
                    ? `${t(UPLOAD_KEY)} ${Object.keys(attachments).length} ${t(FILES_KEY)}`
                    : `${t(UPLOAD_KEY)} ${Object.keys(attachments).length} ${t(FILE_KEY)}`}
                </BFButton>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      )}
      {uploading && <Loader />}
    </div>
  );
};

export default FigmaUpload;
