import { keyToClick } from '@integration-frontends/common/ui';
import React from 'react';
import { Asset, Container } from '@integration-frontends/integration/core/model';
import { useSelector } from 'react-redux';
import { searchAssetsSelectors } from '@integration-frontends/integration/core/application';
import './figma-upload-header.scss';
import { PageNav } from '@integration-frontends/integration/ui/common/layout/page';
import { BackButton } from '@integration-frontends/integration/ui/attachment-selector/common/back-button/back-button';
import { useAttachmentSelectorNavigation } from '@integration-frontends/integration/ui/attachment-selector/navigation';

//TODO: create sharable / reusable component with asset details page header
export interface FigmaUploadPageHeaderProps {
  container: Container;
}

export function FigmaUploadHeader({ container }: FigmaUploadPageHeaderProps) {
  const navigation = useAttachmentSelectorNavigation();
  const searchParams = useSelector(searchAssetsSelectors.searchParams);
  const sortOptions = useSelector(searchAssetsSelectors.sortOptions);

  function returnToShowPage() {
    container
      ? navigation.goToShowPage(container.id, searchParams, sortOptions)
      : navigation.goToContainerSelector();
  }

  return (
    <PageNav className="figma-upload-header">
      <BackButton
        text={container?.name}
        onClick={returnToShowPage}
        onKeyDown={keyToClick}
        tabIndex={0}
      />
    </PageNav>
  );
}
