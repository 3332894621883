import { Popover } from '@headlessui/react';
import { BFIconButton, IconEllipsis } from '@integration-frontends/common/ui';
import { PopoverMenu, PopoverMenuItem } from '@integration-frontends/common/ui/menu/popover-menu';
import { DI_CONTAINER } from '@integration-frontends/core';
import { containerSelectors } from '@integration-frontends/integration/core/application';
import {
  Asset,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import { Trans } from '@lingui/macro';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import { useSelector } from 'react-redux';
import { useAttachmentSelectorNavigation } from '../../../navigation';
import { ReactElementLike } from 'prop-types';

export interface AssetThumbnailMenuProps {
  asset: Asset;
  containerId: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export function AssetThumbnailMenu({
  asset,
  containerId,
  onFocus,
  onBlur,
}: AssetThumbnailMenuProps) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );
  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
  });
  const navigation = useAttachmentSelectorNavigation();

  return (
    <div className="flex w-full justify-end">
      <Popover>
        <Popover.Button onFocus={onFocus} onBlur={onBlur} as="div" ref={setReferenceElement}>
          <BFIconButton
            data-testid="more-options-btn"
            className="bg-bf-white"
            style={{ width: 30, height: 30 }}
            iconElement={(<IconEllipsis />) as ReactElementLike}
            ariaLabel="more actions"
          />
        </Popover.Button>

        <Popover.Panel
          data-testid="more-options-menu-panel-container"
          ref={setPopperElement}
          className="z-100"
          style={styles.popper}
          {...attributes.popper}
        >
          <PopoverMenu>
            <PopoverMenuItem
              data-testid="view-details-action"
              onClick={() => navigation.goToAssetDetails(asset.id, selectedContainer.id)}
              onFocus={onFocus}
              onBlur={onBlur}
            >
              <Trans>View asset details</Trans>
            </PopoverMenuItem>

            <PopoverMenuItem onFocus={onFocus} onBlur={onBlur}>
              <a
                target="_blank"
                href={bfWebsiteService.getAssetPageUrl(selectedContainer, asset)}
                onFocus={onFocus}
                onBlur={onBlur}
              >
                <Trans>Open in Brandfolder</Trans>
              </a>
            </PopoverMenuItem>
          </PopoverMenu>
        </Popover.Panel>
      </Popover>
    </div>
  );
}
