import { DI_CONTAINER } from '@integration-frontends/core';
import {
  RESOURCE_BASE_SORT_OPTIONS,
  SortOptions,
} from '@integration-frontends/integration/core/model';
import {
  SELECT_ATTACHMENT_OPTIONS_TOKEN,
  ISelectAttachmentOptions,
} from '@integration-frontends/integration/core/application';
import React, { useState } from 'react';
import { SectionContent } from '../../common';
import { BFLabel } from '@integration-frontends/common/ui';
import './sorting-selector.scss';
import { Trans, t } from '@lingui/macro';

export interface SortingSelectorProps {
  sortOptions: SortOptions;
  onChange?: (sortOptions: SortOptions) => void;
}

const getTranslatedSortOptionsLabels = () => ({
  createdAtASC: t`Upload date (oldest)`,
  createdAtDESC: t`Upload date (newest)`,
  nameASC: t`Name (A-Z)`,
  nameDESC: t`Name (Z-A)`,
  positionASC: t`Position`,
  updatedAtASC: t`Updated (oldest)`,
  updatedAtDESC: t`Updated (newest)`, 
  popularityASC: t`Least popular`,
  popularityDESC: t`Most popular`,
});

export const getSortOptionsKey = (options: SortOptions) => {
  return `${options.field}${options.direction}`;
};

export function SortingSelector({ sortOptions, onChange }: SortingSelectorProps) {
  const [showOptions, setShowOptions] = useState(false);
  const { showAToZSorting = true }: ISelectAttachmentOptions = DI_CONTAINER.get(
    SELECT_ATTACHMENT_OPTIONS_TOKEN,
  );
  const translatedSortOptionsLabels = getTranslatedSortOptionsLabels();

  function sortOptionsSelected(options: SortOptions) {
    setShowOptions(false);
    onChange(options);
  }

  const getSortOptionsText = (sortOptions: SortOptions): string => {
    const sortOptionsKey = `${sortOptions.field}${sortOptions.direction}`;
    return translatedSortOptionsLabels[sortOptionsKey];
  };

  return (
    <div className="view-options-item z-200 py-sm relative">
      <SectionContent className="gap-sm pd-0 sort-options-selector flex items-center justify-between">
        <BFLabel>
          <Trans>Sort by</Trans>
        </BFLabel>
        <span
          data-testid="selected-sort-option"
          className="select selected-sort-option flex cursor-pointer items-center"
          onClick={() => setShowOptions(!showOptions)}
        >
          {getSortOptionsText(sortOptions)}
        </span>
      </SectionContent>
      <div
        className={`sorting-selectors shadow-border mt-xxs ${
          showOptions === true ? 'visible' : 'invisible'
        }`}
      >
        {RESOURCE_BASE_SORT_OPTIONS.map((sortOptions) => {
          if (showAToZSorting || (!showAToZSorting && sortOptions?.field !== 'name')) {
            return (
              <SectionContent
                data-testid="sort-option"
                key={`${sortOptions?.field}_${sortOptions?.direction}`}
                className="option py-xs cursor-pointer"
                onClick={() => sortOptionsSelected(sortOptions)}
              >
                {getSortOptionsText(sortOptions)}
              </SectionContent>
            );
          }
        })}
      </div>
    </div>
  );
}
