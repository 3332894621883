import { put, takeEvery } from 'typed-redux-saga';
import { push } from 'redux-first-history';
import { pageLoadError } from '../../../../libs/common/app/src';
import { getObservabilityService } from '@integration-frontends/core';
import { call } from 'redux-saga/effects';

export function createPageLoadErrorRedirectEffect(route: string) {
  function* handler(action: ReturnType<typeof pageLoadError>) {
    const { error } = action.payload;

    yield call(getObservabilityService().addError, error);
    yield put(push(route));
  }

  return function* () {
    yield takeEvery(pageLoadError, handler);
  };
}
