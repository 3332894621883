import { backToHome } from '@integration-frontends/integration/core/application';
import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'redux-first-history';

const handler = function* () {
  yield put(push('/'));
};

export function* backToHomeEffects() {
  yield takeEvery(backToHome, handler);
}
